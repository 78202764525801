'use client'

import { useEffect, useState } from 'react'
import { usePathname } from 'next/navigation'
import Link from 'next/link'
import { i18n } from '../i18n-config'
import { setCookie, getCookie, getCookies } from 'cookies-next';
import { BiWorld } from "react-icons/bi";
import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { useRouter } from 'next/navigation'

export default function LocaleSwitcher({ language }) {
  const router = useRouter()

  const [currentLocale, setCurrentLocale] = useState(language)
  const [selected, setSelected] = useState(language)
  const [isOpen, setIsOpen] = useState(false)
  const pathName = usePathname()

  const redirectedPathName = (locale) => {
    const languages = ['en', 'th', 'cn']

    if (!pathName) return '/'
    if (languages.includes(pathName.split('/')[1])) {
      const segments = pathName.split('/')
      segments[1] = locale
      return segments.join('/')
    } else {
      return `/${locale}` + pathName
    }
  }

  // console.log('hu '+ getCookie('NEXT_LOCALE'))
  // console.log('hu ' + currentLocale)
  const setCookieByRoute = () => {
    const languages = ['en', 'th', 'cn']
    let langCookie = getCookie('NEXT_LOCALE')
    if (!pathName) return '/'
    const segments = pathName.split('/')
    if (languages.includes(segments[1]) && (langCookie !== undefined && langCookie !== segments[1])) {
      setCookie('NEXT_LOCALE', segments[1]);
      setSelected(segments[1])
      router.refresh()
    }
  }

  useEffect(() => {
    const triggerLangChange = () => {
      setCookieByRoute()
    }
    triggerLangChange()
  }, [])
  
  
  // setCookieByRoute()

  const setLocaleCookie = (localeValue) => {
    setCookie('NEXT_LOCALE', localeValue);
    setSelected(localeValue)
    router.refresh()
  }

  const toggleOpen = () => {
    setIsOpen(!isOpen)
    // console.log('clicked')
    // console.log(isOpen)
  }

  return (
    <div className="relative z-[100]">
      <Listbox value={selected} onChange={setSelected} className='z-[100]'>
        <div className=" mt-1 z-[100]">
          <Listbox.Button className="relative z-[100] w-full cursor-pointer rounded-lg flex flex-row justify-center items-center py-2 pl-3 pr-3 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 border-[1px] border-light  sm:text-sm">
            <BiWorld color='white' />
            <span className="ml-2 truncate text-light text-xl">{selected.toUpperCase()}</span>
            {/* <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span> */}
          </Listbox.Button>
            <Listbox.Options className="absolute z-[100] mt-1 max-h-60 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {i18n.locales.map((locale) => {
               if (locale !== 'default') {
                return (
                <Listbox.Option
                  key={locale}
                  className={({ active }) =>
                    `z-[100] cursor-default select-none ${
                      active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                  }
                  value={locale}
                >
                  {({ selected }) => (
                    <>
                      <Link  key={locale} onClick={() => setLocaleCookie(locale)} href={redirectedPathName(locale)}>
                        <div className='w-full py-2 text-center text-xl rounded-md bg-light hover:bg-third hover:text-maincolor'>
                        {locale.toUpperCase()}
                        </div>
                      </Link>
                      {/* <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {locale}
                      </span> */}

                    </>
                  )}
                </Listbox.Option>
                ) 
              }
            })}
            </Listbox.Options>
        </div>
      </Listbox>
    </div>
  )
}