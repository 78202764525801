'use client'
import React from 'react'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/navigation'
import { currencies } from '@constants/currencies'
import { getSearchParams } from "@utils";
import { getCookie, setCookie } from 'cookies-next';
import { Listbox } from '@headlessui/react'
import { MdCurrencyExchange } from "react-icons/md";

const CurrencyTab = ({ title }) => {
    const router = useRouter();

    const [currency, setCurrencyType] = useState('thb')
    const [curren, setCurrency] = useState(currencies[0])
    let [addChecked, setAddChecked] = useState(currencies[0].value)

    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        let currencyType = getCookie('currency')
        if (currencyType == undefined) {
            currencyType = 'thb'
        } else {
            setCurrencyType(currencyType)
            setAddChecked(currencyType)
        }

    })

    const openExchangeTab = () => {
        setIsOpen(prevValue => !prevValue)
        let currencyType = getCookie('currency')
        setAddChecked(currencyType)

    }

    const handleUpdateParams = (e) => {
        setCookie('currency', e.value)
        setCurrencyType(e.value)
        router.refresh()

    };

    useEffect(() => {
        const currencyValue = getSearchParams('currency')
        setAddChecked(currencyValue)

        const newValue = currencies.find(obj => obj.value === addChecked);
        // console.log('new value ' + newValue)
        if (newValue != undefined) {
            setCurrency(newValue)
            setCurrencyType(newValue.value)

        }
    }, [handleUpdateParams])

    return (
        <div className="relative z-[100]">
            <Listbox 
                value={curren} 
                onChange={(value) => {
                    setCurrency(value)
                    handleUpdateParams(value)
                }}
            >
        <div className="mt-1 z-[100]">
                <Listbox.Button className="relative z-[100] w-full cursor-pointer rounded-lg flex flex-row justify-center items-center py-2 pl-3 pr-3 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 border-[1px] border-light sm:text-sm">
                <MdCurrencyExchange color='white' /><span className="ml-2 truncate text-light text-xl">{curren.value.toUpperCase()}</span>
                </Listbox.Button>
                <Listbox.Options className="absolute z-[100] mt-1 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    {currencies.map((curr) => (
                        <Listbox.Option
                        key={curr.value}
                        className={({ active }) =>
                            `z-[100] cursor-default select-none ${
                            active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                            }`
                        }
                        value={curr}
                        >
                            <div className='w-full cursor-pointer py-2 text-center text-xl rounded-md bg-light hover:bg-third hover:text-maincolor'>
                                <span>
                                    {curr.value.toUpperCase()}
                                </span>
                            </div>
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
        </div>
            </Listbox>
        </div>
    )
}

export default CurrencyTab